import { render, staticRenderFns } from "./facility-unit-count.vue?vue&type=template&id=6b22ba44&"
import script from "./facility-unit-count.vue?vue&type=script&lang=js&"
export * from "./facility-unit-count.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b22ba44')) {
      api.createRecord('6b22ba44', component.options)
    } else {
      api.reload('6b22ba44', component.options)
    }
    module.hot.accept("./facility-unit-count.vue?vue&type=template&id=6b22ba44&", function () {
      api.rerender('6b22ba44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8ZXgzLav3dV8N/JS/comp/facility-unit-count.vue"
export default component.exports