<template>

    <div v-if="images"
        class="owl-carousel"
        :id="['owl-' + unitid]">
        <div v-for="img in images.slice(0,3)"
            class="item">
            <a>
                <img-fallback :img="img.id"
                    mode="img"
                    img-format="m"
                    vo-msg:placeholder="no.pic.placeholder"></img-fallback>
            </a>

        </div>
    </div>
    <div v-else>
        <img-fallback mode="img"
            vo-msg:placeholder="no.pic.placeholder"></img-fallback>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'img-carousel',
    	props: {
    		images: Array,
    		default: () => [{
    			id: -1,
    			width: 450,
    			height: 300,
    			name: '//via.placeholder.com/450x300&text=No%20Image'
    		}],
    		unitid: Number,
    		unitpath: String

    	},

    	mounted: function() {
    		$("#owl-" + this.unitid).owlCarousel({
    			items: 1,
    			loop: true,
    			center: false,
    			autoplay: false,
    			smartSpeed: 0,
    			lazyLoad: true,
    			animateOut: 'fadeOut',
    			dots: false,
    			nav: true
    		})
    	},

    	methods: {
    		getTarget: function() {
    			return this.getUnitSearchTarget(this.unitid);
    		},
    		getUnitPath: function() {
    			return this.getUnitSearchPath(this.unitpath);
    		},
    		getUnitSearchTarget: function(id) {
    			return 'unit_' + id;
    		},
    		getUnitSearchPath: function(path, o) {
    			/*var sd = this.searchData;

    			var searchKeys = ['from', 'till', 'adults', 'children', 'babys', 'pets', 'petsCount'];

    			var params = [];
    			for (let sp of searchKeys) {
    				if (o && o[sp]) {
    					params.push(sp + '=' + o[sp]);
    				} else if (!isBlank(sd[sp])) {
    					params.push(sp + '=' + sd[sp]);
    				}
    			}

    			if (params.length) {
    				path += '#?' + params.join('&');
    			}*/
    			return path;


    		},
    	}

    };
</script>