import V from 'voUtils/V.js';

export default {
	voVueComponent: 'voffice-max-text-size',
	template: `
    <div v-if="showFull">
     <div ref="full">
         <slot></slot>
     </div>
			<a v-if="textLength > max" class="show-more"
          href=""
          @click.prevent="showFull=false">
          {{showLessText}}
       </a>
    </div>
    <div v-else>
		<div v-html="shortenedContent"></div>
       <a  class="show-more" 
          href=""
          @click.prevent="showFull=true">
          {{showMoreText}}
       </a>
    </div>
  `,
	props: {
		max: Number,
		showMoreText: String,
		showLessText: String
	},
	data: function () {
		return {
			showFull: true,
			textLength: 0,
			shortenedContent: undefined
		};
	},
	mounted: function () {

		var content = this.$refs.full.innerHTML;
		var contentLength = this.$refs.full.textContent.length;
		this.textLength = contentLength;

		if (contentLength > this.max) {
			this.showFull = false;

			let c = content.substr(0, this.max);
			if (c.indexOf('<') >= 0) { // If there's HTML don't want to cut it

				let inTag = false; // I'm in a tag?
				let bag = ''; // Put the characters to be shown here
				let countChars = 0; // Current bag size
				let openTags = []; // Stack for opened tags, so I can close them later
				let tagName = null;
				let j;

				for (var i = 0, r = 0; r <= this.max; i++) {
					if (content[i] == '<' && !inTag) {
						inTag = true;

						// This could be "tag" or "/tag"
						tagName = content.substring(i + 1, content.indexOf('>', i));

						// If its a closing tag
						if (tagName[0] == '/') {


							if (tagName != '/' + openTags[0]) {
								V.logWarn('ERROR en HTML: the top of the stack should be the tag that closes');
							} else {
								openTags.shift(); // Pops the last tag from the open tag stack (the tag is closed in the retult HTML!)
							}

						} else {
							// There are some nasty tags that don't have a close tag like <br/>
							if (tagName.toLowerCase() != 'br') {
								openTags.unshift(tagName); // Add to start the name of the tag that opens
							}
						}
					}
					if (inTag && content[i] == '>') {
						inTag = false;
					}

					if (inTag) { // Add tag name chars to the result
						bag += content.charAt(i);

					} else {
						r++;
						if (countChars <= this.max) {
							bag += content.charAt(i); // Fix to ie 7 not allowing you to reference string characters using the []
							countChars++;
						} else { // Now I have the characters needed

							if (openTags.length > 0) { // I have unclosed tags

								for (j = 0; j < openTags.length; j++) {
									bag += '</' + openTags[j] + '>'; // Close all tags that were opened

									// You could shift the tag from the stack to check if you end with an empty stack, that means you have closed all open tags
								}
								break;
							}
						}
					}
				}
				//c = $('<div/>').html(bag + '<span class="ellip">...</span>').html();
				//let element = document.createElement('DIV');
				c = bag + '<span class="ellip">...</span>';

			} else {
				c += '...';
			}

			this.shortenedContent = c;

		}

		if (!this.shortenedContent) {
			this.shortenedContent = content;
		}




	}

};