import V from 'voUtils/V.js';
import MarkerClusterer from 'voUtils/MarkerClusterer.js';
import {
	debounce
}
from 'voUtils/tools.js';

import {
	loadGmaps
}
from 'voUtils/loader.js';


function pinSymbol(color) {
	return {
		path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
		fillColor: color,
		fillOpacity: 1,
		strokeColor: '#000',
		strokeWeight: 1,
		scale: 1.2
	};
}

const DEFAULT_MARKER_COLOR = '#3548a1';


export default {
	voVueComponent: 'voffice-gmap',
	template: `
<div>
	<div ref="slot">
		<slot></slot>
	</div>
	<div style="width:100%;height:100%" ref="map">
	</div>
</div>
`,
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			}
		},
		initialPos: Array,
		initialZoom: Number,
		mapType: String,
		scrollok: Boolean,
		draggable: String,
		highlight: Number,
		selected: Object,
		markerPos: {
			type: Array,
			default: function () {
				return [10, 54];
			}
		},
		cluster: Boolean
	},
	created: function () {

		var vc = this;
		this.onMarkerClicked = function () {
			vc.$emit('update:selected', this.customData);
		};

		var onResize = debounce(() => {
			this.updateBounds();
		}, 800);
		window.addEventListener("resize", onResize);

	},
	mounted: function () {

		var initialPos = this.initialPos || this.markerPos || [10, 54];
		var initialZoom = this.initialZoom || 5;

		loadGmaps(() => {

			var myStyles = [{
				featureType: "poi.business",
				elementType: "labels",
				stylers: [{
					visibility: "off"
				}]
			}];

			var mapOptions = {
				clickableIcons: false,
				center: new google.maps.LatLng(initialPos[1], initialPos[0]),
				zoom: initialZoom,
				mapTypeId: google.maps.MapTypeId[this.mapType || 'ROADMAP'],
				styles: myStyles
			};

			if (!this.scrollok) {
				mapOptions.scrollwheel = false;
			}

			if (this.draggable === 'false') {
				mapOptions.draggable = false;
			}

			var map = new google.maps.Map(this.$refs.map, mapOptions);
			this.map = map;

			google.maps.event.addListener(map, 'zoom_changed', function () {
				//let zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function (event) {
				if (this.getZoom() > 15 && this.initialZoom == true) {
					// Change max/min zoom here
					this.setZoom(15);
					this.initialZoom = false;
				}
				//google.maps.event.removeListener(zoomChangeBoundsListener);
				//});
			});

			this.infowindow = new google.maps.InfoWindow({
				content: this.$refs.slot,
				maxWidth: 320
			});

			google.maps.event.addListener(this.infowindow, 'closeclick', () => {
				V.log("infowindow closed!");
				this.$emit('update:selected', {});
			});

			if (this.lazyUpdateSingleMarker || this.markerPos.length > 0) {
				this.updateSingleMarker();
			}
			if (this.lazyUpdateMarkers || this.items.length > 0) {
				this.updateMarkers();
			}

			/*
			if (attrs.pos) {
				showMarker(initialPos);
			} else if (toShowList) {
				showList(toShowList);
			} else if (staticMarkers) {
				showStaticMarkers();
			}
         */


		});



	},

	methods: {
		findMarkerById: function (id) {
			if (this.markers) {
				for (let m of this.markers) {
					if (m.customData && m.customData._id == id) {
						return m;
					}
				}
			}
		},
		updateBounds: function () {
			if (this.bounds && this.markers && this.markers.length) {

				this.map.initialZoom = true;
				this.map.fitBounds(this.bounds);
			}
		},
		deleteMarkers: function () {
			if (this.markers) {

				if (this.clusterer) {
					this.clusterer.removeMarkers(this.markers);
				}


				for (let m of this.markers) {
					m.setMap(null);
				}
			}

			this.markers = [];

		},

		updateSingleMarker: function () {

			if (this.map) {


				this.deleteMarkers();

				if (this.markerPos) {
					let latlng = new google.maps.LatLng(this.markerPos[1], this.markerPos[0]);

					let markerOptions = {
						position: latlng,
						map: this.map,
						draggable: false
					};

					let marker = new google.maps.Marker(markerOptions);
					this.markers.push(marker);
				}

			} else {
				this.lazyUpdateSingleMarker = true;
			}

		},

		updateMarkers: function () {

			if (this.map) {

				this.deleteMarkers();

				V.log("gmap items", this.items);


				if (this.items) {
					this.bounds = new google.maps.LatLngBounds();

					for (let item of this.items) {
						let markerOptions;

						if (item.loc) {
							let latlng = new google.maps.LatLng(item.loc.coordinates[1], item.loc.coordinates[0]);

							markerOptions = [{
								position: latlng,
								icon: pinSymbol(DEFAULT_MARKER_COLOR),
								draggable: false,
								customData: item
							}];

						} else if (Array.isArray(item)) {
							//array result
							let latlng = new google.maps.LatLng(item[1], item[0]);
							const lat = item[1];
							const lng = item[0];
							const totalMarkers = item.length - 2;
							const alpha = 360 / totalMarkers;
							const radius = totalMarkers > 1 ? 1 : 0;
							const distance = 50000;
							markerOptions = [];

							for (let i = 2; i < item.length; i++) {
								let p = i - 2;
								let x = Math.cos(alpha * (Math.PI / 180) * p) * radius;
								let y = Math.sin(alpha * (Math.PI / 180) * p) * radius;
								markerOptions.push({
									position: new google.maps.LatLng(
										lat + (y / distance),
										lng + (x / distance)
									), // latlng,
									icon: pinSymbol(DEFAULT_MARKER_COLOR),
									draggable: false,
									customData: {
										_id: item[i],
										lazy: true,
										info: item
									}
								});
							}
						}

						if (markerOptions) {
							for (let i in markerOptions) {
								let markerOpt = markerOptions[i];
								if (!this.cluster) {
									markerOpt.map = this.map;
								}
								let marker = new google.maps.Marker(markerOpt);
								marker.addListener('click', this.onMarkerClicked);
								this.markers.push(marker);
								this.bounds.extend(marker.getPosition());
							}
						}
					}

					this.updateBounds();
					if (this.cluster) {

						if (this.clusterer) {
							this.clusterer.addMarkers(this.markers);
						} else {
							this.clusterer = new MarkerClusterer(this.map, this.markers, {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								maxZoom: 20
							});
						}
					}

				}

			} else {
				this.lazyUpdateMarkers = true;
			}

		},
		unHighlightMarker: function (m) {
			if (this.highlightedMarker) {
				m.setAnimation(null);
				m.setIcon(pinSymbol(DEFAULT_MARKER_COLOR));
				m.setZIndex(m._zIndex);

				this.highlightedMarker = undefined;
			}
		},
		highlightMarker: function (m) {
			m.setAnimation(google.maps.Animation.BOUNCE);
			m.setIcon(pinSymbol('red'));

			m._zIndex = m.getZIndex();
			m.setZIndex(2000);
			this.highlightedMarker = m;
			setTimeout(() => {
				m.setAnimation(null);
			}, 750);
		}
	},
	watch: {
		markerPos: function (newval, oldval) {
			this.updateSingleMarker();
		},
		items: {
			deep: true,
			handler: function (nv) {
				this.updateMarkers();
			}
		},
		highlight: function (id) {

			if (this.selected && this.selected._id) {
				return;
			}

			this.updateBounds();

			this.unHighlightMarker(this.highlightedMarker);

			if (id) {
				let m = this.findMarkerById(id);
				if (m) {
					this.highlightMarker(m);

				}
			}
		},
		selected: function (nv) {
			this.unHighlightMarker(this.highlightedMarker);
			if (!this.cluster) {
				this.updateBounds();
			}

			var m = this.findMarkerById(nv._id);
			if (m) {
				this.infowindow.open(this.map, m);
				this.highlightMarker(m);
			} else {
				this.infowindow.close();
			}

		}

	}
};